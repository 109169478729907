@import "../../styles/imports.scss";

.specificity {
  &.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > :last-child {
      margin-top: 2em;
    }

    &:not(.vertical) {
      @include mediaMinMedium {
        flex-direction: row;
        & > :last-child {
          margin-top: 0;
          margin-left: 2em;
        }
      }
    }

    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 90%;

      &:not(.vertical) {
        @include mediaMinSmall {
          justify-content: center;
          width: 85%;
        }
        @include mediaMinMedium {
          justify-content: flex-start;
          flex-direction: row;
          height: 4em;
          width: 75%;
        }
      }

      & > :first-child {
        border-radius: 0.8em 0.8em 0 0;
        & > :first-child {
          border-radius: 0.8em 0.8em 0 0;
        }
      }

      &:not(.vertical) {
        & > :first-child {
          @include mediaMinMedium {
            border-radius: 0.8em 0 0 0.8em;
          }
          & > :first-child {
            @include mediaMinMedium {
              border-radius: 0.8em 0 0 0.8em;
            }
          }
        }
      }
    }
  }
}
