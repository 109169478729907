@import "../../../../styles/imports.scss";

.specificityWrapper {
  &.mainContainer {
    height: 100%;
    width: 100%;
    .calendarDropdown {
      background-color: white;
      height: 100%;
      width: 100%;
      // cursor: pointer;
      position: relative;
      border-left: 1px solid $border-lightgray;
      border-right: 1px solid $border-lightgray;
      display: flex;
      align-items: center;
      padding: 0.5em 1em;
      user-select: none;

      &:not(.vertical) {
        @include mediaMinMedium {
          // width: 33.33%;
          border-right: none;
          border-top: 1px solid $border-lightgray;
          border-bottom: 1px solid $border-lightgray;

          &:not(.monounit) {
            border-left: none;
          }
        }
      }
      .icon {
        width: 15%;
      }
      .container {
        display: flex;
        width: 85%;
        &To {
          margin-left: 1em;
        }
        .label {
          color: $text-lightgray;
          font-size: 0.8em;
        }
      }
      .dropdownIcon {
        margin-left: auto;
        animation: rotate180b 0.2s normal;
        &Open {
          transform: rotateZ(180deg);
        }
      }

      .calendarDropdownContainer {
        display: flex;
        align-items: center;
        .formGroup {
          display: flex;
          flex-direction: column;
          justify-items: center;
          cursor: pointer;
          &:not(:first-child) {
            margin-left: 1em;
          }
          .label {
            font-size: 0.8em;
            color: $text-lightgray;
            margin-bottom: 0.2em;
          }
          .date {
            font-size: 0.9em;
            &.selected {
              color: $primary-blue;
            }
          }
        }
      }

      .dropdownTab {
        position: absolute;
        top: calc(100% + 0.5rem);
        width: 100%;
        left: 0;
        border: 1px solid #979797;
        border-radius: 0.5em;
        cursor: default;
        ul {
          list-style-type: none;
          padding: 0 1em;
        }
      }

      .datePicker {
        position: absolute;
        z-index: 500;
        top: calc(100% + 10px);
        &:not(.monounit) {
          transform: translateX(-50%);
          left: 50%;
        }
        &.monounit {
          left: 0;
        }
      }
    }
  }
}
