@import "../../../../styles/imports.scss";

.specificityWrapper {
  &.travelersDropdown {
    position: relative;
    height: 100%;
    width: 100%;
    &.travelersDropdownCustom {
      position: relative;
      height: 100%;
      width: 100%;
    }

    .travelersDropdownBox {
      background-color: white;
      height: 100%;
      border: 1px solid;
      border-radius: 0 0 0.8em 0.8em;
      display: flex;
      align-items: center;
      padding: 0.5em 1em;
      user-select: none;
      cursor: pointer;

      &:not(.vertical) {
        @include mediaMinMedium {
          border: 1px solid;
          border-radius: 0 0.8em 0.8em 0;
        }
      }

      .icon {
        width: 15%;
      }
      .container {
        .label {
          font-size: 0.8em;
        }
      }
      .dropdownIcon {
        margin-left: auto;
        animation: rotate180b 0.2s normal;
        &Open {
          transform: rotateZ(180deg);
        }
      }
    }

    .dropdownContainer {
      padding: 1em;
    }

    .inputContainer:not(:first-child) {
      margin-top: 1em;
    }

    .childrenAgesContainer {
      p {
        margin: 1em 0;
      }
    }

    .extended {
      position: absolute;
      width: 250px;
      left: 50%;
      transform: translate(-50%);
    }
  }
}
